export const memberApi = {
  get: {
    method: 'post',
    api: 'backend/member/get'
  },
  getList: {
    method: 'post',
    api: 'backend/member/getList'
  },
  del: {
    method: 'post',
    api: 'backend/member/del'
  },
  save: {
    method: 'post',
    api: 'backend/member/save'
  },
  setexamine: {
    method: 'post',
    api: 'backend/member/setexamine'
  },
  getIndustry: {
    method: 'post',
    api: 'backend/member/getIndustry'
  },
}