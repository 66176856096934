let cacheReg:any={}
export const getReg = (path:any) => {//将路由地址解析为正则用来解析动态路由:/page1/:pageId
    if(cacheReg[path]){
        return cacheReg[path]
    }else if (path) {
        let tmp = path.replace(new RegExp(":.+?((?=/)|$)", "g"), ".+"); 
        let reg = new RegExp('^' + tmp + '$')
        cacheReg[path]=reg;
        return reg;
    }
    return new RegExp('')
}
export const menuTraverser = (obj:any, callback:any) => {//递归遍历菜单
    function traverse(obj:any, index:any, key:any) {
        callback && callback(obj, index, key);//index为层数，obj为当前访问的对象
        if (obj && obj.children) {
            for (let i in obj.children) {
                traverse(obj.children[i], index + 1, i)
            }
        }
    }
    traverse(obj, 0, '');
    return obj;
}
export const searchPath = (tree:any, wantPath = '', deep = true) => {//查询menu树结构子节点路径,deep深度遍历
    let path:any = null;
    function forTree(obj:any, arr:any = []) {
        if (obj instanceof Array) {
            for (let i = 0; i < obj.length; i++) {
                if (path) {//查到就停止
                    return;
                }
                forTree(obj[i], arr);
            }
        } else if (obj instanceof Object) {
            let reg = obj.path && getReg(obj.path)
            if (obj.path && reg.test(wantPath)) {
                path = [...arr, obj]
            } else {
                if (obj.children) {
                    forTree(obj.children, [...arr, obj])
                } else {
                    for (let k in obj) {
                        if (typeof obj[k] == 'object') {
                            forTree(obj[k], arr)
                        }
                    }
                }
            }
        }
    }
    function deepForTree(obj:any, arr:any = []) {
        if (obj instanceof Array) {
            for (let i = 0; i < obj.length; i++) {
                if (path && arr.length < path.length) {
                    return
                }
                deepForTree(obj[i], arr);
            }
        } else if (obj instanceof Object) {
            let reg = obj.path && getReg(obj.path)
            if (obj.path && reg.test(wantPath)) {
                path = [...arr, obj];
            }
            if (obj.children) {
                deepForTree(obj.children, [...arr, obj])
            } else {
                for (let k in obj) {
                    if (typeof obj[k] == 'object') {
                        if (path && arr.length < path.length) {
                            return
                        }
                        deepForTree(obj[k], arr);
                    }
                }
            }
        }

    }

    if (deep) {
        deepForTree(tree)
    } else {
        forTree(tree);
    }
    return path || [];
}