import $api from '@/api/index';
let cacheReqPerm:any = null;

export default {
    namespaced: true,
    state: {
        baseSetting: {
            logo: '',
            name: ''
        },
    },
    getters: {
        
    },
    mutations: {
        setRoutes(state:any, routes:any) {
            state.routes = routes
        },
        setBaseSetting(state:any, option:any) {
            state.baseSetting.logo = option.logo;
            state.baseSetting.name = option.name;
        },
        // 获取用户数据
        setInit(state:any, res:any) {
            state.user = res?.user;
            state.copyright = "<p>Copyright 2020-2022 技术有限公司版权所有<br/> <br/>网站备案号/许可证号：</p>"
            state.admin_status = res?.admin_status
            state.admin = res?.admin||{}
        },
    },
    actions: {
        // 商城初始化数据、权限、用户、修改密码等链接
        getInit({
            dispatch
        }:any, { to, forceUpdate }:any) {
            if (!cacheReqPerm||!forceUpdate) { // 没有缓存或者强制刷新
                cacheReqPerm = new Promise((resolve, reject) => {
                    $api.shopApi.getInit()
                        .then((res:any) => {
                            resolve(res)
                        }).catch((err:any) => {
                            reject(err)
                        }).finally(() => {
                            cacheReqPerm = null;
                        })
                })
            }
           
            cacheReqPerm.then((res:any)=> dispatch('setInit',{response:res,toPath:to}))

            return cacheReqPerm
        },
        // 初始化店铺设置
        setInit({commit}:any,{response,toPath}:any){
            if (response.code === 1) {

                commit('setInit', response.data);

                commit('menu/refreshMenu', toPath, {
                    root: true
                })
                commit('setBaseSetting', response.data.settings || {});
            } else {
                commit('setInit', null);
            }
        },
    }
};
 