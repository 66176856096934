import store from '@/store'
import {clearErrorCache} from '@/utils/directives'
import {deepCopy, deepCompare} from '@/assets/js/tools'
import getSessionId from '@/api/config/getSessionId'
// import permMapper from '../permit/permMap.js'
// import redirectRoutes from './redirectRoutes.js'

export const beforeEach = (to:any, from:any, next:any) => {
  to.path.indexOf('/decorate/') > -1 && store.commit('layout/setRouterEntering', true)
  localStorage.setItem('reload', '');//用来强制刷新页面的标识，''是指下次报错可以强制刷新，1是指下次有错也不能刷新
  next()
}
export const afterEach = (to:any) => {
  store.commit('layout/setRouterEntering', false)
  store.commit('setCurRoute', to)
  clearErrorCache();//页面错误定位功能
}

class BeforeGuard {

  /* 是否重定向跳转 */
  rediectPath(to:any, from:any, next:any) {
    return new Promise<void>((resolve, reject) => {
        if (to.path == from.path) {
            let toQuery = deepCopy(to.query,[])
            let fromQuery = deepCopy(from.query,[])
            delete toQuery.timestamp
            delete fromQuery.timestamp
            if (deepCompare(toQuery, fromQuery)) {//防止反复重定向
                next(false)
                reject('反复重定向')
            }
        }
        resolve()
    })
  }

  /* 调整参数 */
  parsePath(to:any, from:any, next:any) {
    return new Promise<void>((resolve, reject) => {
        if (to.meta?.timestamp && !to.query?.timestamp) {
            next({
                path: to.path,
                query: {
                    ...to.query,
                    timestamp: new Date().getTime()
                }
            })
            reject('调整参数')

        } else {
            resolve()
        }
    })
  }

  /* 检测登录 */
  checkLogin(to:any, from:any, next:any) {
    let sessionId_s = store.state.account?.sessionId
    let handler = this.checkAloneLogin
    if (typeof handler !== 'function') {
        return Promise.reject('当前渠道登录方式未注册')
    }
    return handler.call(this, {
        sessionId_s,
        next,
        to
    })
  }

  checkAloneLogin({sessionId_s, to, next}:any) {
    return new Promise<void>((resolve, reject) => {
        let flag:any = !!sessionId_s
        if (!sessionId_s) {
            getSessionId()
            if (flag) {
                if (to.path == '/account/login') {
                    next('/')
                    reject('当前已登录')
                } else {
                    resolve()
                }
            } else if (to.meta && to.meta.module == 'account') {
                next()
                reject('checkAloneLogin---重定向')
            } else {
                // 清空用户信息
                store.commit('alone/setUserInfo', {})
                next({
                    path: '/account/login'
                })
                reject('重定向登录' + to.path)
            }
        } else {
            flag = store.dispatch('alone/getLoginStatus');
            flag.then((flag:any)=>{
                if (flag) {
                    if (to.path == '/account/login') {
                        next('/')
                        reject('当前已登录')
                    } else {
                        resolve()
                    }
                } else if (to.meta && to.meta.module == 'account') {
                    next()
                    reject('checkAloneLogin---重定向')
                } else {
                    // 清空用户信息
                    store.commit('alone/setUserInfo', {})
                    next({
                        path: '/account/login'
                    })
                    reject('重定向登录' + to.path)
                }
            })
        }
    })
}
}

export const beforeGuard = new BeforeGuard()