export const aloneApi = {
  getSessionId: { // 获取session-id
      method: 'get',
      api: 'backend/shop/getSessionId'
  },
  login: { // 登录
      method: 'post',
      api: 'backend/login/login'
  },
  getLoginStatus: { // 获取当前登录状态
    method: 'get',
    api: 'backend/shop/loginStatus'
  },
  logout: { // 获取当前登录状态
    method: 'get',
    api: 'backend/login/logout'
  },
}