import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_SubMenu = _resolveComponent("SubMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_main = _resolveComponent("el-main")!
  const _component_el_container = _resolveComponent("el-container")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_container, { style: {"display":"block"} }, {
      default: _withCtx(() => [
        _createVNode(_component_NavBar),
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_SubMenu),
            _createVNode(_component_el_container, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_main, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_router_view)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}