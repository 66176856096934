export const shopApi = {
  // 初始化信息
  uploadImg: {
    method: 'post',
    api: 'backend/shop/uploadImg',
  },
  changePwd: { // 获取当前登录状态
    method: 'post',
    api: 'backend/shop/changePwd'
  },
}