export const adApi = {
  get: {
    method: 'post',
    api: 'backend/ad/get'
  },
  getList: {
    method: 'post',
    api: 'backend/ad/getList'
  },
  del: {
    method: 'post',
    api: 'backend/ad/del'
  },
  save: {
    method: 'post',
    api: 'backend/ad/save'
  },
}