/**
 * vue-router配置，
 * 最终会被展开合并进入./index.js
 */
 import Layout from '@/views/layout/index.vue'

 export default [
     {
         name: '应用',
         path: '/application/ad',
         perm: '',
         component: Layout,
         meta: {module: 'application'},
         children: [
            {
                name: '广告列表',
                path: '/application/ad',
                perm: '',
                meta: {module: 'application'},
                show:false,
                component: () => import('@/views/application/ad.vue'),
            },
            {
                name: '添加广告',
                path: '/application/ad/addDetail',
                perm: '',
                meta: {module: 'application'},
                show:false,
                component: () => import('@/views/application/adDetail.vue'),
            },
            {
                name: '编辑广告',
                path: '/application/ad/detail/:id',
                perm: '',
                meta: {module: 'application'},
                show:false,
                component: () => import('@/views/application/adDetail.vue'),
            },
            {
                name: '模板列表',
                path: '/application/temp',
                perm: '',
                meta: {module: 'application'},
                show:false,
                component: () => import('@/views/application/temp.vue'),
            },
            {
                name: '添加模板',
                path: '/application/temp/addDetail',
                perm: '',
                meta: {module: 'application'},
                show:false,
                component: () => import('@/views/application/tempDetail.vue'),
            },
            {
                name: '编辑模板',
                path: '/application/temp/detail/:id',
                perm: '',
                meta: {module: 'application'},
                show:false,
                component: () => import('@/views/application/tempDetail.vue'),
            },
            {
                name: '反馈列表',
                path: '/application/feedback',
                perm: '',
                meta: {module: 'application'},
                show:false,
                component: () => import('@/views/application/feedback.vue'),
            },
         ],
     }
 ]