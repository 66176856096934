<template>
  <el-header>
    <el-menu
      :default-active="current"
      class="el-menu-demo"
      mode="horizontal"
      :ellipsis="false"
    >
      <div class="logo">
        <img src="@/assets/img/logo1.png" width="150" style="margin-top:10px"/>
      </div>
      <el-menu-item @click="gopath(item.path)" v-for="item in getMenuList" :key="item.path" :index="item.name">
        <span>{{ item.title }}</span>
      </el-menu-item>
      <div class="flex-grow" />
      <el-menu-item index="username">{{username}}</el-menu-item>
      <el-menu-item index="changepwd">
        <div @click="changePwd">
            <el-icon :size="20">
                <Lock />
            </el-icon>
        </div>
      </el-menu-item>
      <el-menu-item index="outLogins" @click="outLogins">退出</el-menu-item>
    </el-menu>
  </el-header>
</template>

<script>
import { defineComponent, ref } from 'vue'
import {mapGetters, mapState} from 'vuex'
import permMapper from '../../../router/permit/permMap'
export default defineComponent({
setup() {
  const current = ref(['/home/index/index']);
  return {
    visible:false,
    username:'',
    current,
  };
},
computed: {
      ...mapGetters('menu', {
          getSecondLevelMenu: 'getSecondLevelMenu',
          menuNav: 'getFirstLevelMenu',
          getBreadcrumb: 'getBreadcrumb'
      }),
      ...mapState('layout', {
          isOpen: (state) => state.isOpen,
      }),
      ...mapState('config', {
          rightLink: (state) => state.right_top_link,
          user: (state) => state.user || {},
          baseSetting: (state) => state.baseSetting,
          platform: (state) => state.platform,
          permList: state => state.user?.perms || [],
      }),

      ...mapState('account', {
          shopId: (state) => state.shop.shopId,
          shopType: (state) => state.shop.shopType,
      }),
      adminPerm() {//只有超管可以进入系统设置
          return this.$store.state.config.user?.is_root == 1
      },
      getMenuList() {
          let arr = this.menuNav.map(item => {
              let newItem = {
                  ...item,
              };
              if (typeof item.show == 'undefined' || item.show !== false) {//show不是false就是true;还要取决于权限的true/false
                  newItem.show = newItem.perm;//判断有没有展示权限
              }
              if (newItem.show) {//如果子菜单一个都没有权限就隐藏该一级菜单
                  newItem.show = this.getSecondLevelMenu(item.path.split('/')[1]).filter(item => item.perm).filter(item => this.permMapper.hasPerm(item.path, false)).length > 0;
              }
              return newItem;
          });
          return arr;
      },
  },
  watch: {
      permList: {
          immediate: true,
          handler() {
              this.permMapper = permMapper.getPermsMap(this.permList)
          }
      },
      '$route.path': {
          immediate: true,
          handler() {
              this.current = this.$route.meta.module
              this.breadcrumb = this.getBreadcrumb(this.$route.path)
              this.currentPage = this.breadcrumb[this.breadcrumb.length - 1]
              if (this.breadcrumb[0]) {
                  this.activeName = this.breadcrumb[0]._pageId;
              }
          }
      }
  },
  methods: {
    gopath(url){
        this.$router.push({
            path:url
        })
    },
    changePwd(){
        this.$router.push({
            path:'/home/changePwd'
        })
    },
      outLogins(){
        this.aloneLogout()
      },
      goBack() {
          if (
              typeof this.currentPage.backPath == 'string' &&
              this.currentPage.backPath.indexOf('/') == 0
          ) {
              this.$router.replace({
                  path: this.currentPage.backPath,
              })
          } else {
              this.$router.go(-1)
          }
      },
      handleDropdown(name) {
          switch (name) {
              case 'alone_logout':
                  this.aloneLogout()
                  break;
              case 'settings':
                  this.settings()
                  break;
              default:
                  break;
          }
      },
      aloneLogout() {
          this.$store.dispatch('alone/loginOut').then(() => {
            console.log(1111)
              this.$router.replace('/account/login')
          })
      },
      settings() {
          this.$router.push('/account/member')
      },
      // 首页跳转链接
      jumpLink(item) {
          window.open(`${item.scheme}${item.jump_url}`)
      },
  },
  mounted () {
    this.username = localStorage.getItem('username')
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.logo{
  display: inline-block;
  width: 200px;
  text-align: center;
  height: 55px;
}
.flex-grow, .grow {
    flex-grow: 1;
}
.btn{
  display: inline-block;
  padding:0 20px;
  text-align: center;
  line-height: 55px; 
}
</style>