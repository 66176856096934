export default {
  title: '用户',
  name: 'member',
  path: '/member/index',
  show: true,
  icon: 'icon-zuocedaohang-shouye1',
  perm: '',
  zIndex: 2,
  children: {
    member: {
        title: '用户列表',
        name: 'member',
        path: '/member/index',
        icon: "icon-zuocedaohang-shouye1",
        perm:'',
        show: ($route:any) =>
                $route?.path === "/member/index"
    }
  }
}
